::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: #aaa;
}

::-webkit-scrollbar-thumb {
    background-color: #335ddc;
    border-radius: 8px;
}

body {
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
}
#login-form-rg_fechnac {
    width: 360px !important;
    height: 42px !important;
}
.ant-select-selector{
    width: 100% !important;
    height: 50px !important;
    padding: 9px 15px !important;
}

.login-page-rg {
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #00aa80;
}

.login-box-rg {
    padding: 3% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    max-width: 1000px;
    background-color: white;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
    overflow: hidden;
    margin: 0 auto;
    border-radius: 12px;
}

#login-form-rg {
    flex: 1 0 100%;
    max-width: 780px !important;
    width: 100%;
}

#login-form-rg p {
    margin-bottom: 10px;
}

#login-form-rg p.form-title-rg {
    color: #333333;
    font-family: "Josefin Sans", sans-serif;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
    padding-bottom: 5%;
}

#login-form-rg .ant-form-item-label > label.ant-form-item-required::before {
    display: none;
}

.ant-form-item-control-input-content {
    text-align: left;
}

#login-form-rg .ant-input-affix-wrapper {
    padding: 12px 15px;
}

.login-form-rg-text{
    height: 48px;
}

#login-form-rg_rut{
    height: 48px;
}

#login-form-rg .ant-btn {
    height: 42px;
    letter-spacing: 1px;
    border-radius: 6px;
}

.login-form-button-rg {
    width: 100%;
    background-color: #00aa80 !important;
}

.item-form-rg-select {
   height: 30px;
}

.illustration-wrapper-rg {
    display: flex;
    align-items: flex-end;
    max-width: 800px;
    min-height: 100%;
    /* background-color: #fffdf2; */
}

.illustration-wrapper-rg img {
    display: block;
    width: 90%;
    border-radius: 3%;
}




@media screen and (max-width: 1023px) {

  
    .login-box {
        flex-direction: column;
        box-shadow: none;
    }

    .illustration-wrapper-rg {
        max-width: 90%;
        min-height: auto;
    }

    #login-form-rg {
        max-width: 100%;
    }
}


.buttons-style{
    max-width: 70%;
    padding-left: 30%;
}

@media screen and (max-width: 600px) {

    .login-page-rg {
        padding: 5%;
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        background: #00aa80;
    }
    .login-box-rg {
        padding: 3% !important;
        display: flex;
        width: 90%;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        max-width: 1000px;
        background-color: white;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
        overflow: hidden;
        margin: 0 auto;
        border-radius: 12px;
    }

    #login-form-rg_fechnac {
        width: 170px !important;
        height: 42px !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill:disabled{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}