@keyframes fadeInCM {
    0% {
        transform: translateY(-25%);
    }
    50%{
        transform: translateY(4%);
    }
    65%{
        transform: translateY(-2%);
    }
    80%{
        transform: translateY(2%);
    }
    95%{
        transform: translateY(-1%);
    }
    100% {
        transform: translateY(0%);
    }
}

.popupcm {
    animation-name: fadeInCM;
    animation-duration: 0.4s;
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    left: 0px;
    list-style-type: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    text-align: left;
    top: 0px;
    overflow: hidden;
    z-index: 1999;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.popupcm li {
    clear: both;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    margin: 0;
    padding: 5px 12px;
    transition: all .3s;
    white-space: nowrap;
    -webkit-transition: all .3s;
}

.popupcm span {
    clear: both;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    margin: 0;
    padding: 5px 12px;
    transition: all .3s;
    white-space: nowrap;
    -webkit-transition: all .3s;
}

.popupcm li:hover {
    background-color: #e6f7ff;
}

.popupcm li > i {
    margin-right: 8px;
}