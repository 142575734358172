.custom-filter-button{
    cursor: pointer;
    margin-left: 3.5px;
}

.ant-tag-blue .custom-filter-button{
    color: #91caff;
}

.ant-tag-blue .custom-filter-button:hover{
    color: #4e9ee9;
}

.ant-tag-blue .custom-filter-button:active{
    color: #1a64dc;
}

.ant-tag-red .custom-filter-button{
    color: #ffa39e;
}

.ant-tag-red .custom-filter-button:hover{
    color: #ed6b64;
}

.ant-tag-red .custom-filter-button:active{
    color: #cf1322;
}

.ant-tag-default .custom-filter-button{
    color: #bfbfbf;
}

.ant-tag-default .custom-filter-button:hover{
    color: #8c8c8c;
}

.ant-tag-default .custom-filter-button:active{
    color: #1f1f1f;
}